

.cursor {
	display: none;
}

body {
	/* --cursor-dot: #c87e4f;
	--cursor-circle: #c87e4f;	 */

    --cursor-dot: #fff;
	--cursor-circle: #fff;
}

@media (any-pointer:fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		z-index: 10000;
	}

	.cursor--1 .cursor__inner {
		fill: var(--cursor-dot);
	}
	
	.cursor--2 .cursor__inner {
		fill: none;
		stroke: var(--cursor-circle);
		stroke-width: 1px;
	}

	.no-js .cursor {
		display: none;
	}

	.cursor-fade {
		display: none;
	}
}